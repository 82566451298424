<template>
   <body class="grid min-h-screen place-items-center bg-gray-400 print:min-h-0 font-body text-sm">
    <main
      class="m-4 h-[297mm] w-[210mm] overflow-hidden rounded-md bg-white p-8 shadow-lg print:m-0 print:h-screen print:w-screen print:rounded-none print:shadow-none"
    >
      <h1 class="text-4xl font-title">Brent Nequin</h1>
      <div class="flex">
        <p v-for="contact, index in contacts" v-bind:key="contact.name">
          {{ contact.text }}<span v-if="index != Object.keys(contact).length" class="mx-0.5">•</span>
        </p>
      </div>
      
      <hr class="my-2 border-gray-900 border-1 rounded">

      <h2 class="text-2xl font-section-title">WORK EXPERIENCE</h2>
      <div v-for="job, index in experience" v-bind:key="`${job.companyTitle}-${index}`" class="mb-2">
        <div class="flex justify-between">
          <div class="flex">
            <h3 class="font-title font-bold">{{ job.companyTitle }}</h3>
            <p v-if="job.companySubTitle" class="mx-0.5">-</p>
            <p v-if="job.companySubTitle">{{ job.companySubTitle }}</p>
          </div>
          <p>{{ job.location }}</p>
        </div>
        <div class="flex justify-between">
          <p class="italic">{{ job.title }}</p>
          <div class="flex">
            {{ job.startDate }}
            <p class="mx-0.5">-</p>
            {{ job.endDate }}
          </div>
        </div>
        <ul class="list-disc ml-4">
          <li v-for="duty, index in job.duties" v-bind:key="index">{{ duty }}</li>
        </ul>
      </div>

      <h2 class="text-2xl font-section-title">EDUCATION</h2>
      <div v-for="degree, index in education" v-bind:key="index" class="mb-2">
        <div class="flex justify-between">
          <div class="flex">
            <h3 class="font-title font-bold">{{ degree.universityName }}</h3>
            <p class="mx-0.5">-</p>
            <p>{{ degree.location }}</p>
          </div>
          <div class="flex">
            {{ degree.startDate }}
            <p class="mx-0.5">-</p>
            {{ degree.endDate }}
          </div>
        </div>
        <div class="flex justify-between">
          <p class="italic">{{ degree.degree }}</p>
        </div>
      </div>



    </main>
  </body>
</template>

<script lang="ts">
import data from '@/assets/data.json'
import { Contact } from './models/contact';
import { Job } from './models/experience';
import { Degree } from './models/education';

export default {
  data() {
    return {
      contacts: data['contacts'] as Contact[],
      experience: data['experience'] as Job[],
      education: data['education'] as Degree[],
      skills: data['skills'] as { categoryName: string, items: string[] }[],
      projects: data['projects'],
      certifications: data['certifications']
    }
  }
}
</script>

<style>
</style>
